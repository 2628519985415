import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { navigate } from 'gatsby';

const CallbackPage = () => {
    const { handleRedirectCallback, isLoading, error } = useAuth0();

    useEffect(() => {
        const handleAuth = async () => {
            try {
                await handleRedirectCallback();
                navigate('/'); // Redirect to your desired page
            } catch (err) {
                console.error(err);
            }
        };

        handleAuth();
    }, [handleRedirectCallback]);

    if (isLoading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Oops... {error.message}</p>;
    }

    return null;
};

export default CallbackPage;